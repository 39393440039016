var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card-actions",
    [
      _vm.align === "right" ? _c("v-spacer") : _vm._e(),
      _vm._l(_vm.actions, function (action, i) {
        return _c(
          "v-btn",
          {
            key: "action" + i,
            attrs: {
              color: action.color || "grey darken-1",
              text: action.text || true,
              dark: action.dark || false,
              disabled: action.disabled || false,
            },
            on: { click: action.callback },
          },
          [_vm._v(" " + _vm._s(action.label) + " ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }