<template>
    <v-card-actions>
        <v-spacer v-if="align==='right'" />
        <v-btn
            v-for="action,i in actions"
            :key="'action'+i"
            @click="action.callback"
            :color="action.color || 'grey darken-1'"
            :text="action.text || true"
            :dark="action.dark || false"
            :disabled="action.disabled || false"
            >
            {{ action.label }}
        </v-btn>
    </v-card-actions>
</template>
<script>
export default {
    props: {
        align: {
            type: String,
            default: 'right'
        },
        /*
        actions: [{
            label (String),
            color (String) = 'grey darken-1',
            text (Boolean) = true,
            dark (Boolean) = false,
            disabled = false,
            callback (function) = null,
        }, ...]
        */
        actions: {
            default: []
        }
    },
}
</script>
