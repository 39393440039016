export default {
  timeOffsetMinFromLocalTimezone: (new Date()).getTimezoneOffset(),

  userRole: {
    worker: 'worker',
    workerLeader: 'worker_leader',
    requester: 'requester',
    administrator: 'administrator',
  },

  userRoleGroup: {
    workable: 'workable',
    workerManageable: 'worker_manageable',
    requestable: 'requestable',
  },

  jobAssignability: {
    unlimited: 'unlimited',
    oncePerJobClass: 'once_per_job_class',
    oncePerJob: 'once_per_job',
  },

  jobAssignmentStatus: {
    started: 'started',
    cancelled: 'cancelled',
    finished: 'finished',
    timeup: 'timeup',
    expired: 'expired',
    reserved: 'reserved',
  },

  jobProgressDataType: {
    noData: 'no_data',
    rational: 'rational',
    percentage: 'percentage',
    rate: 'rate',
    label: 'label',
  },

  tableColumnViewType: {
    number: 'number',
    string: 'string',
    date: 'date',
    arrayOfString: 'array_of_string',
  },

  tableColumnDataType: {
    number: 'number',
    string: 'string',
    nullOrNumber: 'null_or_number',
    nullOrString: 'null_or_string',
    arrayOfString: 'array_of_string',
  },

  jobClassFilter: {
    all: 'all',
    open: 'open',
    closed: 'closed',
    openForWorker: 'open_for_worker',
    closedForWorker: 'closed_for_worker',
    allRequested: 'all_requested',
    openRequested: 'open_requested',
    closedRequested: 'closed_requested',
  },

  jobClassReportFilter: {
    all: 'all',
    requested: 'requested',
    reported: 'reported',
  },

  jobAssignmentFilter: {
    all: 'all',
    final: 'final',
    nonFinal: 'non_final',
    allAssigned: 'all_assigned',
    finalAssigned: 'final_assigned',
    nonFinalAssigned: 'non_final_assigned',
    allRequested: 'all_requested',
    finalRequested: 'final_requested',
    nonFinalRequested: 'non_final_requested',
  },

  sortingOrder: {
    ascending: 'ascending',
    descending: 'descending',
  },
};
