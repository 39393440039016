export const required =
    value => !!value || "This field is required";

export const notEmpty =
    value => value.length>0 || "Select at least one item";

export const alphanumeric =
    value => {
        const pattern = /^[a-zA-Z0-9]+$/;
        return pattern.test(value) || 'Only alphabets and numbers are allowed';
    };

export const email =
    value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Invalid e-mail.'
    };

export const number =
    value => {
        const pattern = /^-?[0-9][0-9,.]*$/;
        return pattern.test(value) || 'Invalid number.'
    };

export const integer =
    value => {
        const pattern = /^-?[0-9]+$/;
        return pattern.test(value) || 'Invalid number.'
    };

export const positiveInteger =
    value => {
        return /^[0-9]+$/.test(value) && parseInt(value)>0
    };

export const numbers =
    value => {  // only for comboboxes. involves side effects.
        const pattern = /^[0-9]*$/;
        var ret = true;
        for(const i in value) {
            if(!pattern.test(value[i])) {
                ret = false; value.splice(i,1); break;
            }
        }
        return ret || '';
    };

export const reg = function(pattern, message, value) {
        return pattern.test(value) || message
    };

export const stringShorterThan = function(maxLength) {
        return value => required(value) && value.length<maxLength || `Value length must be shorter than ${maxLength} characters`;
    };

export default {
    required,
    notEmpty,
    alphanumeric,
    email,
    number,
    integer,
    positiveInteger,
    numbers,
    reg,
    stringShorterThan,
}
